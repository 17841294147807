@import url("//fonts.googleapis.com/css2?family=Fahkwang:ital,wght@0,300;0,400;0,600;1,400&display=swap");
/* http://meyerweb.com/eric/tools/css/reset/ */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box; }

.block {
  display: block; }

.inline {
  display: inline; }

.inline-block {
  display: inline-block; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.fixed {
  position: fixed; }

.hide {
  display: none; }

.w1 {
  width: 100%; }

.container--l {
  max-width: 1400px; }

.container--m {
  max-width: 1200px; }

.container--s {
  max-width: 750px; }

.height-100 {
  height: 100vh; }

.fit-x {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0; }

.fit-t {
  top: 0; }

.fit-b {
  bottom: 0; }

.fit-l {
  left: 0; }

.fit-r {
  right: 0; }

.c1 {
  color: #35D3E8; }

.c2 {
  color: #FF4E42; }

.c3 {
  color: #FFEA51; }

.cb {
  color: #002839; }

.ci {
  color: inherit; }

.bg-cc {
  background-color: currentColor; }

.tsx {
  font-size: 1rem; }

.ts1 {
  font-size: 4.5rem; }

.ts2 {
  font-size: 3rem; }

.ts3 {
  font-size: 2rem; }

.ts4 {
  font-size: 1.5rem; }

.ts5 {
  font-size: 1rem; }

.ts6 {
  font-size: 0.875rem; }

:root {
  color: #002839;
  font-family: 'Fahkwang', Helvetica, Arial, sans-serif;
  font-size: 100%;
  font-weight: 300;
  -webkit-font-smoothing: antialiased; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .page__subtitle p, .h4, .h5, .h6 {
  font-weight: 300; }

h1, h2, h3, h4, h5, h6 {
  margin: 2ex 0 .6ex; }

h1, .h1 {
  font-size: 2.5rem;
  line-height: 1.1; }
  @media (min-width: 48em) {
    h1, .h1 {
      font-size: 3.25rem; } }

h2, .h2 {
  font-size: 2rem;
  line-height: 1.2; }
  @media (min-width: 48em) {
    h2, .h2 {
      font-size: 2.5rem; } }

h3, .h3, .page__subtitle p {
  font-size: 1.5rem;
  line-height: 1.2; }
  @media (min-width: 48em) {
    h3, .h3, .page__subtitle p {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: 1rem;
  line-height: 1.4; }
  @media (min-width: 48em) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 0.875rem;
  line-height: 1.6;
  font-weight: 400; }
  @media (min-width: 48em) {
    h5, .h5 {
      font-size: 1rem; } }

h6, .h6 {
  font-size: 0.6rem;
  line-height: 1.6;
  font-weight: 400; }
  @media (min-width: 48em) {
    h6, .h6 {
      font-size: 0.875rem; } }

p, .p {
  font-size: 1.2rem;
  line-height: 1.7;
  font-weight: 400; }

p {
  margin: 2ex 0; }

hr {
  display: block;
  background-color: currentColor;
  color: inherit;
  border: 0;
  height: 1px;
  width: 100%;
  margin: 0; }

b, strong, .strong {
  font-weight: 600; }

.uppercase {
  text-transform: uppercase; }

a {
  color: inherit; }

.light {
  font-weight: 300; }

em {
  font-style: italic; }

small {
  font-size: 0.9em; }

.wysiwyg a {
  color: #3333cc !important; }
  .wysiwyg a:focus, .wysiwyg a:hover {
    outline: 0;
    color: #002839 !important; }

.mxa, .mla, .mha {
  margin-left: auto; }

.mxa, .mra, .mha {
  margin-right: auto; }

.mxa, .mta, .mva {
  margin-top: auto; }

.mxa, .mba, .mva {
  margin-bottom: auto; }

.mt0, .mv0, .mx0 {
  margin-top: 0em; }

.mb0, .mv0, .mx0 {
  margin-bottom: 0em; }

.ml0, .mh0, .mx0 {
  margin-left: 0em; }

.mr0, .mh0, .mx0 {
  margin-right: 0em; }

.pt0, .pv0, .px0 {
  padding-top: 0em; }

.pb0, .pv0, .px0 {
  padding-bottom: 0em; }

.pl0, .ph0, .px0 {
  padding-left: 0em; }

.pr0, .ph0, .px0 {
  padding-right: 0em; }

.mt025, .mv025, .mx025 {
  margin-top: 0.25em; }

.mb025, .mv025, .mx025 {
  margin-bottom: 0.25em; }

.ml025, .mh025, .mx025 {
  margin-left: 0.25em; }

.mr025, .mh025, .mx025 {
  margin-right: 0.25em; }

.pt025, .pv025, .px025 {
  padding-top: 0.25em; }

.pb025, .pv025, .px025 {
  padding-bottom: 0.25em; }

.pl025, .ph025, .px025 {
  padding-left: 0.25em; }

.pr025, .ph025, .px025 {
  padding-right: 0.25em; }

.mt05, .mv05, .mx05 {
  margin-top: 0.5em; }

.mb05, .mv05, .mx05 {
  margin-bottom: 0.5em; }

.ml05, .mh05, .mx05 {
  margin-left: 0.5em; }

.mr05, .mh05, .mx05 {
  margin-right: 0.5em; }

.pt05, .pv05, .px05 {
  padding-top: 0.5em; }

.pb05, .pv05, .px05 {
  padding-bottom: 0.5em; }

.pl05, .ph05, .px05 {
  padding-left: 0.5em; }

.pr05, .ph05, .px05 {
  padding-right: 0.5em; }

.mt1, .mv1, .mx1 {
  margin-top: 1em; }

.mb1, .mv1, .mx1 {
  margin-bottom: 1em; }

.ml1, .mh1, .mx1 {
  margin-left: 1em; }

.mr1, .mh1, .mx1 {
  margin-right: 1em; }

.pt1, .pv1, .px1 {
  padding-top: 1em; }

.pb1, .pv1, .px1 {
  padding-bottom: 1em; }

.pl1, .ph1, .px1 {
  padding-left: 1em; }

.pr1, .ph1, .px1 {
  padding-right: 1em; }

.mt2, .mv2, .mx2 {
  margin-top: 2em; }

.mb2, .mv2, .mx2 {
  margin-bottom: 2em; }

.ml2, .mh2, .mx2 {
  margin-left: 2em; }

.mr2, .mh2, .mx2 {
  margin-right: 2em; }

.pt2, .pv2, .px2 {
  padding-top: 2em; }

.pb2, .pv2, .px2 {
  padding-bottom: 2em; }

.pl2, .ph2, .px2 {
  padding-left: 2em; }

.pr2, .ph2, .px2 {
  padding-right: 2em; }

button {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: 'Fahkwang', Helvetica, Arial, sans-serif; }

button[disabled],
input[disabled] {
  cursor: default; }

button.button,
.button,
a[role="button"],
.button[role="button"],
input[type="submit"].button {
  display: block;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  padding: 0.7em 2em;
  text-decoration: none;
  text-align: center; }

form {
  margin: 0; }

input,
select,
textarea {
  outline: 0;
  border: 0; }

label,
input,
select {
  position: relative; }

input[type="text"],
input[type="email"],
input[type="search"],
input[type="tel"],
input[type="password"],
select {
  padding: .35em .65em;
  outline: 0; }

.flex {
  display: flex; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-items-start {
  align-items: flex-start; }

.flex-items-center {
  align-items: center; }

.flex-justify-start {
  justify-content: flex-start; }

.flex-justify-center {
  justify-content: center; }

.flex-auto {
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0; }

.nav {
  padding-top: 1.2em;
  z-index: 1000;
  background-color: white;
  font-weight: 600;
  letter-spacing: 0.2px; }
  @media (min-width: 48em) {
    .nav {
      text-align: right; }
      .nav .nav__inner a {
        margin-right: 0;
        margin-left: 3em; } }
  .nav hr {
    margin-top: 1.2em; }
  .nav .nav__logo {
    color: #002839;
    text-decoration: none;
    width: 100%;
    z-index: 1; }
    @media (min-width: 48em) {
      .nav .nav__logo {
        margin-bottom: 0;
        width: auto; } }
  .foot .nav {
    padding-top: 0;
    padding-bottom: 1.2em; }
    .foot .nav hr {
      margin-top: 0;
      margin-bottom: 1.2em; }
  .nav .nav__inner {
    display: none;
    background-color: white;
    padding-top: 4em;
    z-index: 0;
    padding-left: 0;
    padding-right: 0; }
    .nav .nav__inner.is-visible {
      display: block; }
    @media (min-width: 48em) {
      .nav .nav__inner {
        padding: 0;
        display: inline-block;
        position: relative; } }
    .nav .nav__inner a {
      text-decoration: none; }
      .nav .nav__inner a:focus, .nav .nav__inner a:hover {
        outline: 0; }
        .nav .nav__inner a:focus span, .nav .nav__inner a:hover span {
          color: #3333cc !important; }
      @media (min-width: 48em) {
        .nav .nav__inner a {
          margin-right: 2em;
          display: inline-block;
          padding: 0; }
          .nav .nav__inner a:nth-of-type(3) {
            margin-right: 0; } }
      .nav .nav__inner a span {
        color: #002839; }
      .nav .nav__inner a.is-active span {
        color: #3333cc !important; }

.hamburger {
  width: 30px;
  height: 30px;
  color: var(--orange); }
  @media (min-width: 48em) {
    .hamburger {
      display: none; } }
  .hamburger:not(.is-active):hover .hamburger__bar:first-child {
    transform: translateY(-12px); }
  .hamburger:not(.is-active):hover .hamburger__bar:last-child {
    transform: translateY(12px); }

.hamburger__bar {
  height: 4px;
  width: 2em;
  background-color: currentColor;
  transition: transform 100ms ease-out; }
  .is-active .hamburger__bar {
    opacity: 0; }
  .is-active .hamburger__bar:first-child, .is-active .hamburger__bar:last-child {
    opacity: 1; }
  .hamburger__bar:first-child {
    transform: translateY(-10px); }
    .is-active .hamburger__bar:first-child {
      transform: rotate(45deg) translateY(-1px); }
  .hamburger__bar:last-child {
    transform: translateY(10px); }
    .is-active .hamburger__bar:last-child {
      transform: rotate(-45deg) translateX(1px); }

.item a {
  font-weight: 600;
  text-decoration: none; }

.item__image {
  width: 100%;
  overflow: hidden;
  padding-top: 50%; }
  @media (min-width: 48em) {
    .item__image {
      padding-top: 20%;
      width: 33.33333%; } }
  .item__image img {
    object-fit: cover;
    height: 100%; }

@media (min-width: 48em) {
  .item__info {
    width: 66.66666%;
    padding-left: 2em; } }

.item__view {
  color: #3333cc !important; }
  .item__view:focus, .item__view:hover {
    outline: 0;
    color: #002839 !important; }

.question-outer {
  min-height: 100vh; }

.question {
  font-weight: 300; }
  .question button {
    margin: 0 0.5em 0.5em 0;
    text-align: left;
    padding: 0.4em 0.8em 0.5em;
    border: 1px solid currentColor;
    font-weight: 300; }
    .question button:focus, .question button:hover {
      color: white;
      background-color: #3333cc !important;
      outline: 0; }

.gif {
  background-color: rgba(255, 255, 255, 0.9);
  cursor: crosshair;
  z-index: 1000;
  width: 100%; }
  .gif img {
    width: 100%;
    height: 100%;
    margin: auto;
    object-fit: contain; }

.fav {
  border: 1px solid currentColor; }
  .fav a {
    text-decoration: none; }

.header-spacer {
  height: 66px; }

.clear::before, .clear::after {
  content: ' ';
  display: table; }

.clear::after {
  clear: both; }

.outer {
  padding-left: 1em;
  padding-right: 1em; }
  @media (min-width: 48em) {
    .outer {
      padding-left: 2em;
      padding-right: 2em; } }

.putz {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1001; }

.putz__inner {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 4px;
  background-color: currentColor;
  transition: transform 200ms ease-in-out;
  transform: translateX(-100%); }

.arrow {
  display: inline-block;
  position: relative;
  height: 0;
  width: 0;
  border-top: 0.25em solid currentColor;
  border-right: 0.25em solid currentColor;
  border-left: 0.25em solid transparent;
  border-bottom: 0.25em solid transparent; }
  .arrow::after {
    content: '';
    display: block;
    position: absolute;
    height: 4px;
    background-color: currentColor;
    width: 0.4em;
    transform: rotate(-45deg) translateX(-0.2em) translateY(-0.21em); }

.client-logo {
  max-width: 50%; }
  @media (min-width: 48em) {
    .client-logo {
      max-width: 33%; } }
  .client-logo svg {
    width: 100%;
    height: auto; }

.work__image {
  overflow: hidden;
  margin-bottom: 1em; }
  @media (min-width: 48em) {
    .work__image:nth-of-type(even) {
      float: left;
      max-width: calc(50% - 0.5em);
      max-height: calc(50% - 0.5em); }
    .work__image:nth-of-type(odd) {
      float: right;
      max-width: calc(50% - 0.5em);
      max-height: calc(50% - 0.5em); }
      .work__image:nth-of-type(odd):last-of-type {
        float: right;
        max-width: calc(50% - 0.5em);
        max-height: calc(50% - 0.5em);
        margin-left: 0; }
    .work__image:nth-of-type(3n+1) {
      float: left;
      max-width: 75%;
      margin-left: 12.5%; }
    .work__image:last-of-type {
      float: left;
      max-width: 75%;
      margin-left: 12.5%;
      max-height: 100%; } }
  .work__image img {
    max-width: 100%;
    max-height: 100%; }

.work__image__caption {
  margin-top: 0.25em;
  border: 1px solid currentColor; }
  .work__image__caption p {
    margin: 0; }

.about__image {
  width: 100%;
  margin-bottom: 1em; }
  @media (min-width: 48em) {
    .about__image {
      width: 50%; }
      .about__image:nth-of-type(1) {
        padding-right: 0.5em; }
      .about__image:nth-of-type(2) {
        padding-left: 0.5em; } }
  .about__image img {
    max-width: 100%;
    max-height: 100%; }

.contact .subject-line {
  cursor: pointer; }
  .contact .subject-line:hover ~ h6 {
    opacity: 1; }

.contact h6 {
  opacity: 0; }

.contact button {
  padding: 0.3em 0.5em 0.3em;
  border: 1px solid currentColor;
  color: #3333cc !important; }
  .contact button svg {
    width: 30px;
    height: 30px;
    display: block; }
  .contact button:focus, .contact button:hover {
    outline: 0;
    color: #002839 !important; }

.contact h5 {
  white-space: nowrap; }

.touch .contact .subject-line ~ h6 {
  display: none !important; }

.misc {
  width: 100%; }

@media (min-width: 48em) {
  .misc__inner {
    margin-left: -25px;
    margin-right: -25px; } }

.misc__item {
  padding-bottom: 50px; }
  .misc__item img {
    max-width: 100%; }
  @media (min-width: 48em) {
    .misc__item {
      padding-left: 25px;
      padding-right: 25px; } }
  @media (max-width: 48em) {
    .misc__item {
      width: 100% !important; } }
